  
.thumbnail {
    position: relative;
}

.caption {
    position: absolute;
    top: 40%;
    left: 0;
    width: 100%;
}

body{
	overflow-x: hidden;
}

@media only screen and (max-width:500px) {
  /* For mobile phones: */
  #test{
	font-size: 11px;
}
}
